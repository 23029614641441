import * as React from "react";
import { HeadFC } from "gatsby";
import { PageHead } from "../components/page-head";

const NotFoundPage = () => {
  return (
    <main>
      <h1>Página não encontrada.</h1>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => (
  <PageHead pageTitle="Página não encontrada" />
);
